<template>
  <div class="c-library__body" @mouseleave="resetActive">

    <div class="c-library__card" v-for="(dt, i) in listShare" :key="i" @mouseover="setActive(dt.title)">
      <div class="c-library__card-container" @click="openLink(dt.link)">
        <img class="c-library__card-img" :src="active == dt.title ? dt.imgHover : dt.img" :alt="dt.title" />
        <div class="c-library__card-content" :class="{ 'c-library__card-content-long': dt.isLong }">
          <p class="c-library__card-date">{{ dt.date }}</p>
          <h3 class="c-library__card-desc">{{ dt.content }}</h3>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'NewsCard',
  data() {
    return {
      screenWidth: window.innerWidth,
      listShare: [
        {
          img: require('@/assets/images/businesstime.svg'),
          imgHover: require('@/assets/images/businesstime-hover.svg'),
          title: 'bt-new',
          isLong: true,
          date: '1 August 2022',
          content: 'Fintech firm Sparrow obtains MAS licence to provide digital payment token services',
          link: 'https://www.businesstimes.com.sg/garage/fintech-firm-sparrow-obtains-mas-licence-to-provide-digital-payment-token-services'
        },
        {
          img: require('@/assets/images/yahoo-inactive.svg'),
          imgHover: require('@/assets/images/yahoo-active.svg'),
          title: 'yahoo-new',
          isLong: true,
          date: '24 November 2021',
          content: 'Sparrow offers PayNow services for institutional clients',
          link: 'https://finance.yahoo.com/news/sparrow-offers-paynow-services-institutional-011200413.html'
        },
        {
          img: require('@/assets/images/fintech-inactive.svg'),
          imgHover: require('@/assets/images/fintech-active.svg'),
          title: 'fintech-new',
          isLong: true,
          date: '23 November 2021',
          content: 'Why Cryptocurrencies Should Be Part of Your Company’s Portfolio Diversification',
          link: 'https://fintechnews.sg/57465/sponsoredpost/why-cryptocurrencies-should-be-part-of-your-companys-portfolio-diversification/'
        },
        {
          img: require('@/assets/images/yahoo-inactive.svg'),
          imgHover: require('@/assets/images/yahoo-active.svg'),
          title: 'yahoo',
          isLong: true,
          date: '10 November 2021',
          content: 'Sparrow Raises US$4.4 Million Series A for Options Trading Platform with Gain Loyal Pte Ltd',
          link: 'https://finance.yahoo.com/news/sparrow-raises-us-4-4-024100786.html'
        },
        {
          img: require('@/assets/images/business-inactive.svg'),
          imgHover: require('@/assets/images/business-active.svg'),
          title: 'business',
          isLong: true,
          date: '02 November 2021',
          content: 'Bridging traditional finance with digital asset solutions',
          link: ' https://sbr.com.sg/co-written-partner/sponsored-articles/bridging-traditional-finance-digital-asset-solutions'
        },
        {
          img: require('@/assets/images/forbes-inactive.svg'),
          imgHover: require('@/assets/images/forbes-active.svg'),
          title: 'forbes',
          isLong: false,
          date: '13 August 2021',
          content: 'Sparrow shares why cryptocurrency is not a one-size-fits-all investment plan',
          link: ' https://forbesasiacustom.com/sparrow-a-trusted-digital-asset-wealth-management-specialist/'
        },
        {
          img: require('@/assets/images/techinasia-inactive.svg'),
          imgHover: require('@/assets/images/techinasia-active.svg'),
          title: 'techinasia',
          isLong: false,
          date: '29 June 2020',
          content: 'Sparrow makes options trading more accessible with tailored tools',
          link: 'https://www.techinasia.com/sparrow-3m-bitmex'
        },
        {
          img: require('@/assets/images/techcrunch-inactive.svg'),
          imgHover: require('@/assets/images/techcrunch-active.svg'),
          title: 'techcrunch',
          isLong: false,
          date: '23 June 2020',
          content: 'Sparrow raises USD 3.5 million Series A for trading platform',
          link: 'https://techcrunch.com/2020/06/23/singapore-based-options-trading-platform-sparrow-raises-3-5-million-series-a/?guccounter=1'
        },
        {
          img: require('@/assets/images/theblock-inactive.svg'),
          imgHover: require('@/assets/images/theblock-active.svg'),
          title: 'theblock',
          isLong: false,
          date: '23 June 2020',
          content: 'Sparrow applies for Payment Services License in Singapore',
          link: 'https://www.theblockcrypto.com/post/69279/bitmex-parent-leads-3-5-million-series-a-for-crypto-options-exchange-sparrow'
        },
        {
          img: require('@/assets/images/bitcoin-inactive.svg'),
          imgHover: require('@/assets/images/bitcoin-active.svg'),
          title: 'bitcoin',
          isLong: true,
          date: '15 June 2019',
          content: 'Sparrow discusses Bitcoin and Ethereum trading',
          link: 'https://bitcoinexchangeguide.com/sparrow-exchange/'
        },
        {
          img: require('@/assets/images/fintech-inactive.svg'),
          imgHover: require('@/assets/images/fintech-active.svg'),
          title: 'fintech',
          isLong: false,
          date: '11 June 2019',
          content: 'LuneX backed Sparrow with Cryptocurrency Trading Platform launch',
          link: 'https://fintechnews.sg/31299/blockchain/sparrow-crypto-bitcoin-ethereum-trading-liquidity-smart-contracts/'
        },
        // {
        //   img:require('@/assets/images/techcrunch-inactive.svg'),
        //   imgHover:require('@/assets/images/techcrunch-active.svg'),
        //   title:'techcrunch',
        //   date:'23 June 2020',
        //   content:'Sparrow raises USD 3.5 million Series A for options trading platform',
        //   link:'https://techcrunch.com/2020/06/23/singapore-based-options-trading-platform-sparrow-raises-3-5-million-series-a/?guccounter=1'
        // },
        // {
        //   img:require('@/assets/images/techinasia-inactive.svg'),
        //   imgHover:require('@/assets/images/techinasia-active.svg'),
        //   title:'techinasia',
        //   date:'29 June 2020',
        //   content:'Sparrow makes options trading more accessible with tailored tools',
        //   link:'https://www.techinasia.com/sparrow-3m-bitmex'
        // },
        // {
        //   img:require('@/assets/images/fintech-inactive.svg'),
        //   imgHover:require('@/assets/images/fintech-active.svg'),
        //   title:'fintech',
        //   date:'11 June 2019',
        //   content:'LuneX backed Sparrow with Cryptocurrency Trading Platform launch',
        //   link:'https://fintechnews.sg/31299/blockchain/sparrow-crypto-bitcoin-ethereum-trading-liquidity-smart-contracts/'
        // },
        // {
        //   img:require('@/assets/images/bitcoin-inactive.svg'),
        //   imgHover:require('@/assets/images/bitcoin-active.svg'),
        //   title:'bitcoin',
        //   date:'15 June 2019',
        //   content:'Sparrow discusses Bitcoin and Ethereum trading',
        //   link:'https://bitcoinexchangeguide.com/sparrow-exchange/'
        // },
        // {
        //   img:require('@/assets/images/theblock-inactive.svg'),
        //   imgHover:require('@/assets/images/theblock-active.svg'),
        //   title:'theblock',
        //   date:'23 June 2020',
        //   content:'Sparrow applies for Payment Services License in Singapore',
        //   link:'https://www.theblockcrypto.com/post/69279/bitmex-parent-leads-3-5-million-series-a-for-crypto-options-exchange-sparrow'
        // },
      ],
      active: '',
    }
  },
  computed: {
    device() {
      var dev = this.listShare;
      for (var i = 0; i < dev.length; i++) {
        if (this.screenWidth <= 620) {
          dev[i].isLong = false;
          dev[2].isLong = true;
        } else if (this.screenWidth <= 1279) {
          dev[i].isLong = false;
          dev[8].isLong = true;
        } else {
          dev[i].isLong = false;
          dev[0].isLong = true;
          dev[1].isLong = true;
          dev[2].isLong = true;
          dev[3].isLong = true;
          dev[8].isLong = true;
        }
      }

      return dev;
    }
  },
  methods: {
    setActive(element) {
      this.active = element;
    },
    resetActive() {
      this.active = '';
    },
  }
}
</script>
